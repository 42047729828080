import React, { useEffect, useState } from "react"
import { JobHistoryPost } from "../utils/types"
import { fetchJobHistoryPosts } from "../utils/apiCalls"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./BlogPostCarousell.css"
import styles from "./BlogPostCarousell.module.css"
import classNames from "classnames"
import { sortByValueFunction } from "../utils/helperFunctions"
import {globalVars} from "../globalVars";
import {posts as postsList} from "../hardResponses/posts";

function BlogPostCarousell({ category }: { category: string }) {
  const [isLoading, setLoading] = useState<boolean | string>()
  const [posts, setPosts] = useState<JobHistoryPost[]>([])
  const [activeIndex, setActiveIndex] = useState(-1)

  useEffect(() => {
    // const ac = new AbortController()

    try {
      if(globalVars.useProxyApiCalls){
        const res = postsList(category);
        if(res) setPosts(res?.data.posts.nodes as any);
      }else{
      setLoading(true)
      fetchJobHistoryPosts(category).then((res) => {
        if (res) {
          setPosts([...res])
          setLoading("DONE")
        } else {
          setLoading("error")
        }
      })
      }
    } catch (e) {
      // setLoading('error')
    }
    // return () => ac.abort()
  }, [category])

  const responsive = {
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    bigDesktop: {
      breakpoint: { max: 4000, min: 2000 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 735 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 735, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  return (
    <>
      {posts.length === 0 && isLoading ? null : posts.length === 0 ? null : ( //TODO add error message/component
        <>
          {/*<Header style={{ textAlign: "center" }}>Zeitspiegel</Header>*/}
          <div className={styles.carouselWrapper}>
            <div className={styles.carouselLine}></div>
            <Carousel
              responsive={responsive}
              // autoPlay={true}
              // autoPlaySpeed={8000}
              infinite={false}
              renderButtonGroupOutside
              partialVisible={false}
              containerClass={styles.carouselContainer}
            >
              {posts
                .sort((el1, el2) =>
                  sortByValueFunction(
                    el1?.history?.lefttorightorder,
                    el2?.history?.lefttorightorder,
                  ),
                )
                .map((post, index) => {
                  console.log(post)
                  const tillDato = post?.history?.to === "NOW" ? "bis Dato" : post?.history?.to
                  return (
                    <div key={index} style={{ height: "30rem", marginLeft: "1rem" }}>
                      <div
                        className={classNames(styles.expandableWrapper, {
                          [styles.expandableWrapperExpanded]: activeIndex === index,
                        })}
                        style={activeIndex === index ? { maxHeight: "auto", height: "auto" } : {}}
                        onMouseEnter={() => setActiveIndex(activeIndex === index ? -1 : index)}
                        onMouseLeave={() => setActiveIndex(activeIndex === index ? -1 : index)}
                      >
                        <div
                          className={styles.cardImageWrapper}
                          style={{
                            backgroundImage: post?.featuredImage?.node?.sourceUrl
                              ? `url("${post?.featuredImage?.node?.sourceUrl}")`
                              : 'url("http://3.124.217.242/wp-content/uploads/2021/01/bg_kontakt.jpg")',
                          }}
                        >
                          <div className={styles.timePeriod}>
                            {post?.history?.from === post?.history?.to
                              ? post?.history?.from
                              : post?.history?.from + " - " + tillDato}
                          </div>
                        </div>
                        <div className={styles.title}>{post?.title}</div>
                        <div
                          className={
                            activeIndex === index ? styles.expandable : styles.expandableCollapsed
                          }
                        >
                          <p
                            className={
                              activeIndex === index
                                ? styles.expandableWrapperTextExpanded
                                : styles.expandableWrapperText
                            }
                            dangerouslySetInnerHTML={{ __html: post?.history?.description }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </Carousel>
          </div>
        </>
      )}
    </>
  )
}

export default BlogPostCarousell
