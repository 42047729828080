import {CoinItem} from "../utils/types";
import ThreeDim from "../assets/images/3dim-tr_s-w2S.png";
import Wide from "../assets/images/Gosch_Carola-061R13x18_wide.jpg";
import Zugang from "../assets/images/Gosch_Carola-055R-scaled.jpg";
import Handlungen from "../assets/images/Gosch_Carola-068R13x18_wide.jpg";
import ErfahrungsRaum from "../assets/images/Gosch_Carola-078R13x18_wide.jpg";
import Kontakt from "../assets/images/bg_kontakt_wide-1.jpg";
import Philantropie from "../assets/images/20191022_105458_wide-scaled.jpg";

export function pageByUri(uri: string): ({ data: { pageBy: CoinItem } } | undefined) {

    if (uri === "/") {
        return {
            "data": {
                "pageBy": {
                    "id": "cG9zdDo0MQ==",
                    "content": `<p style=\"float: left; padding-right: 1rem;\"><img loading=\"lazy\" class=\"alignnone wp-image-353\" src=\"${ThreeDim}\" alt=\"\" width=\"400\" height=\"294\" srcset=\"${ThreeDim} 583w, ${ThreeDim} 300w\" sizes=\"(max-width: 400px) 100vw, 400px\" \/><\/p>\n<p>Carola Gosch Consulting steht f\u00fcr eine Kultur der Achtsamkeit, der gegenseitigen Wertsch\u00e4tzung und des Lernens voneinander.<\/p>\n<p style=\"margin-bottom: 13rem;\">Das Zusammenspiel Ihrer und meiner<strong> Kompetenzen<\/strong> und <strong>Erfahrungen<\/strong> sowie das gegenseitige <strong>Vertrauen<\/strong> erm\u00f6glichen einen Raum der Co-Creation von der Ausgangslage bis zur Umsetzung der L\u00f6sung.<\/p>\n`,
                    "title": "Der Raum",
                    "uri": "\/",
                    "featuredImageId": "cG9zdDoyNTM=",
                    "featuredImageDatabaseId": 253,
                    "featuredImage": {"node": {"sourceUrl": Wide}},
                    "postCarousellCategory": {"postCarousellCategory": "jobhistory"}
                }
            },
        };
    }
    if (uri === "/de/der-zugang/") {
        return {
            "data": {
                "pageBy": {
                    "id": "cG9zdDo0OA==",
                    "content": "\r\n<div class=\"wp-block-columns alignwide\">\r\n<div class=\"wp-block-column\">\r\n<p>Ich reiche Ihnen eine dritte Hand zur Aktion, ohne Ihnen die Verantwortung f\u00fcr die Dinge aus der Hand zu nehmen. Mein Anliegen ist, Sie darin zu unterst\u00fctzen, Ihren Weg zu finden und ihn kraftvoll zu gehen. Ich halte unseren Co-Working Space bis zur operativen Umsetzung der gemeinsam gefundenen L\u00f6sung sicher und lebendig.<\/p>\r\n<\/div>\r\n\r\n\r\n\r\n<div class=\"wp-block-column\">\r\n<ul style=\"margin: 0;\" >\r\n<li>Sie haben eine Fragestellung oder eine Vision, der Sie unbedingt nachgehen wollen? <\/li>\r\n<li>Sie haben den Mut zu Ver\u00e4nderung?\r\n\r\n<\/li>\r\n<li>Es gibt eine Synergie zwischen Ihrer Fragestellung und meinen Kompetenzen?\r\n\r\n<\/li>\r\n<li>Wir k\u00f6nnen auf einer pers\u00f6nlichen Ebene den Raum f\u00fcr die Co-Creation schaffen?\r\n\r\n<\/li>\r\n<li>Wir vertrauen auf unsere gemeinsame L\u00f6sungskompetenz?<\/li>\r\n<\/ul>\r\n<\/div>\r\n<\/div>\r\n",
                    "title": "Der Zugang",
                    "uri": "\/de\/der-zugang\/",
                    "featuredImageId": "cG9zdDoyNTc=",
                    "featuredImageDatabaseId": 257,
                    "featuredImage": {"node": {"sourceUrl": Zugang}},
                    "postCarousellCategory": {"postCarousellCategory": "jobhistory"}
                }
            }
        }
    }
    if (uri === "/de/die-handlungen/") {
        return {
            "data": {
                "pageBy": {
                    "id": "cG9zdDo1Mw==",
                    "content": "\r\n<div class=\"wp-block-columns alignwide\">\r\n<div class=\"wp-block-column\">\r\n<p>Gemeinsamer Prozess<\/p>\r\n\r\n\r\n\r\n<ul>\r\n<li>Wie ist die Ausgangslage? Was ist gut, was soll bleiben? Was soll gehen, was sich ver\u00e4ndern? Was sind m\u00f6gliche Herausforderungen? Welche Erwartungen gibt es?\u00a0<\/li>\r\n<li>Im Zusammenspiel entdecken und entwickeln wir gute Wege und \u00fcberpr\u00fcfen sie<\/li>\r\n<li>Ich begleite Sie auch beim Umsetzen und Sichern der gefundenen L\u00f6sungen.\u00a0<\/li>\r\n<li>Wir bringen das Neue auf den Boden.<\/li>\r\n<\/ul>\r\n<\/div>\r\n\r\n\r\n\r\n<div class=\"wp-block-column\">\r\n<p>Lebendige Beratungskultur<\/p>\r\n\r\n\r\n\r\n<ul>\r\n<li>Ihr Engagement, Ihre pers\u00f6nliche und fachliche Kompetenz sowie ihre pers\u00f6nliche Basis bilden die Basis.<\/li>\r\n<li>Ich lade Sie ein, erworbenes Wissen wertzusch\u00e4tzen und damit Neuland zu betreten.<\/li>\r\n<li>Wir lassen uns leiten von Neugierde und Inspirationen, erlauben mutiges Forschen und einen Blick \u00fcber den Tellerrand.<\/li>\r\n<li>Der Weg fokussiert auf L\u00f6sungen und Ergebnisse und wei\u00df zugleich um seinen Wert an sich.<\/li>\r\n<\/ul>\r\n<\/div>\r\n<\/div>\r\n\r\n\r\n\r\n<p>Es zeigt sich immer wieder, welche \u00dcberraschungen &#8211; manchmal fast Unvorstellbares &#8211; sich ereignen, wenn Menschen auf ihre Synergien und die Kraft der Co-Kreation vertrauen<\/p>\r\n",
                    "title": "Die Handlungen",
                    "uri": "\/de\/die-handlungen\/",
                    "featuredImageId": "cG9zdDoyNTk=",
                    "featuredImageDatabaseId": 259,
                    "featuredImage": {"node": {"sourceUrl": Handlungen}},
                    "postCarousellCategory": {"postCarousellCategory": "jobhistory"}
                }
            }
        }
    }
    if (uri === "/de/der-erfahrungsraum/") {
        return {
            "data": {
                "pageBy": {
                    "id": "cG9zdDo1OA==",
                    "content": "\r\n<p>Meine langj\u00e4hrige Erfahrung ist die Grundlage f\u00fcr ein gesichertes Ergebnis und den gemeinsamen Erfolg<\/p>\r\n\r\n\r\n\r\n<ul>\r\n<li>Ich biete einen fundierten fachlichen Hintergrund und breites Methodenwissen in den Bereichen Informationstechnologie, Prozessmanagement, Organisationsentwicklung und Strategieumsetzung.<\/li>\r\n<li>Meine Expertise kommt aus der Arbeit mit b\u00f6rsennotierten Unternehmen, Ministerien und Lokalverwaltungen aber auch aus ehrenamtlichem Engagement, entwicklungspolitischen Projekten und pers\u00f6nlichen Beratungen.<\/li>\r\n<li>Ich bin mit einem aktiven Expert*innen-Netzwerk verbunden, welches die gemeinsame Arbeit in spezifischen Fragestellungen erg\u00e4nzen kann.<\/li>\r\n<\/ul>\r\n\r\n\r\n\r\n<p>Als Pers\u00f6nlichkeit zeichnen mich Identifikation mit den gemeinsamen Zielen, strukturierte Vorgehensweise, Gelassenheit, Vertrauen und Humor, auch in herausfordernden Situationen, aus.<\/p>\r\n",
                    "title": "Der Erfahrungsraum",
                    "uri": "\/de\/der-erfahrungsraum\/",
                    "featuredImageId": "cG9zdDoyNTg=",
                    "featuredImageDatabaseId": 258,
                    "featuredImage": {"node": {"sourceUrl": ErfahrungsRaum}},
                    "postCarousellCategory": {"postCarousellCategory": "der-raum"}
                }
            }
        }
    }
    if (uri === "/de/kontakt-impressum/") {
        return {
            "data": {
                "pageBy": {
                    "id": "cG9zdDoxNDA=",
                    "content": "<p><strong>GO-BAL GmbH<\/strong><br \/>\nObere Augartenstra\u00dfe 10\/35<br \/>\nA-1020 Wien<br \/>\nT +43 (0) 676 &#8211; 79 06 407<br \/>\n<a href=\"mailto:office@carolagosch.com\">office@carolagosch.com<\/a><\/p>\n<p><strong>Firmenbuch:<\/strong> 540219 s<br \/>\nLandes- als Handelsgericht Wien<br \/>\nUID: ATU 760 251 05<\/p>\n<p><strong>Urheberrecht:<\/strong><br \/>\nDer Inhalt, einschlie\u00dflich Bilder, und die Gestaltung der Webseite unterliegen dem Schutz des Urheberrechts und anderer Gesetze zum Schutz geistigen Eigentums, unabh\u00e4ngig von jeglicher Kennzeichnung (z.B. \u00a9, \u00ae, oder TM Vermerk).<\/p>\n<p><strong>Datenschutz<\/strong><br \/>\nDer Schutz Ihrer pers\u00f6nlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschlie\u00dflich auf Grundlage der gesetzlichen Bestimmungen (DSGVO 2018).<\/p>\n",
                    "title": "Kontakt & Impressum",
                    "uri": "\/de\/kontakt-impressum\/",
                    "featuredImageId": "cG9zdDozMDI=",
                    "featuredImageDatabaseId": 302,
                    "featuredImage": {"node": {"sourceUrl": Kontakt}},
                    "postCarousellCategory": {"postCarousellCategory": ""}
                }
            }
        }
    }
    if (uri === "/de/philantropie/") {
        return {
            "data": {
                "pageBy": {
                    "id": "cG9zdDoyNjQ=",
                    "content": "<p>Seit 2013 engagiert sich Carola Gosch mit ihrem gemeinn\u00fctzigen Verein <a href=\"http:\/\/www.rootsforlife.info\">Roots for Life<\/a> f\u00fcr Frauen und M\u00e4dchen in Nepal.<br \/>\nDer Verein hat sich die Hilfe zur Selbsthilfe zum Ziel gesetzt, und betreut mittlerweile 4 D\u00f6rfer beim Aufbau lokaler Frauenkooperativen, biologischem Gem\u00fcseanbau, Einkommensgenerierung und Stipendien f\u00fcr schulische Ausbildung der M\u00e4dchen.<\/p>\n",
                    "title": "Philantropie",
                    "uri": "\/philantropie\/",
                    "featuredImageId": "cG9zdDoyNjU=",
                    "featuredImageDatabaseId": 265,
                    "featuredImage": {"node": {"sourceUrl": Philantropie}},
                    "postCarousellCategory": {"postCarousellCategory": ""}
                }
            }
        }
    }
    return undefined;
}