import React, { useEffect, useRef, useState } from "react"
import styles from "./BaseLayout.module.css"
import { BaseLayoutHeader } from "./BaseLayoutHeader"
import { BaseLayoutFooter } from "./BaseLayoutFooter"
import { HeaderQueryRes } from "../utils/types"
import { fetchHeaderPages } from "../utils/apiCalls"
import {globalVars} from "../globalVars";
import {pages} from "../hardResponses/pages";

export const footerLinksObject = [
  { text: "Kontakt & Impressum", to: "de/kontakt-impressum/" },
  { text: "Philantropie", to: "de/philantropie/" },
]
const BaseLayout = ({ children, ...props }: { children: any }) => {
  const [headerPages, setHeaderPages] = useState<HeaderQueryRes[]>([])

  useEffect(() => {
    try {
      if(globalVars.useProxyApiCalls){
        const hPages = pages("DE");
        setHeaderPages(hPages?.data.pages.nodes as any);
      }else{
      if (headerPages.length === 0) {
        // setLoading(true)
        fetchHeaderPages().then((res) => {
          if (res) {
            setHeaderPages(res)
          } else {
            // setLoading('error')
          }
        })
      }
      }
    } catch (e) {
      // setLoading('error')
    }
  }, [headerPages.length])

  const mainContainerRef = useRef<HTMLDivElement>(null)
  return (
    <div className={styles.baseLayout}>
      <div className={styles.layoutDivider}>
        <BaseLayoutHeader
          footerLinksObject={footerLinksObject}
          mainContainerRef={mainContainerRef}
          headerPages={headerPages}
        />
        <div className={styles.mainContainer}>
          <div ref={mainContainerRef} className={styles.sideContainer} />
          <div className={styles.mainContentContainer}>{children}</div>
          <div className={styles.sideContainer} />
        </div>
      </div>
      <BaseLayoutFooter footerLinksObject={footerLinksObject} />
    </div>
  )
}

export default BaseLayout
