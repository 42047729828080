import { FooterLinksType } from "../utils/types"
import styles from "./BaseLayout.module.css"
import { Link } from "react-router-dom"
import React from "react"

export const BaseLayoutFooter = ({
  footerLinksObject,
}: {
  footerLinksObject: FooterLinksType[]
}) => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerLinks}>© Carola Gosch Consulting</div>
      <div className={styles.footerLinks}>
        {footerLinksObject.map((linkObj, index) => (
          <div key={index}>
            <Link
              style={{
                color: "inherit",
                textDecoration: "inherit",
              }}
              to={linkObj.to}
            >
              {linkObj.text}
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}
