import React, { useEffect, useState } from "react"
import { fetchFrontPageQuery } from "../../utils/apiCalls"
import "./Home.css"
import { CoinItem } from "../../utils/types"
import { ContentSection } from "../Page/Page"
import {globalVars} from "../../globalVars";
import {pageByUri} from "../../hardResponses/pageBy";
// import { wpPageHardCoded } from "./fallback.js"

function Home(): JSX.Element {
  const frontPageQueryResults = []
  const [isLoading, setLoading] = useState<boolean | string>()
  const [wpPage, setWPPage] = useState<CoinItem>()
  console.log(wpPage);

  useEffect(() => {
    try {
      if(globalVars.useProxyApiCalls){
        const res = pageByUri("/")
        setWPPage(res?.data.pageBy);
      }else{
      if (frontPageQueryResults.length === 0) {
        setLoading(true)
        fetchFrontPageQuery().then((res) => {
          if (res) {
            setWPPage(res)
            setLoading("DONE")
          } else {
            setLoading("error")
          }
        })
      }}
    } catch (e) {
      setLoading("error")
    }
    // return () => ac.abort()
  }, [frontPageQueryResults.length])

  return isLoading === "error" ? (
    <ContentSection pathname={"/"} wpPage={wpPage} />
  ) : (
    <ContentSection pathname={"/"} wpPage={wpPage} />
  )
}

export default Home
