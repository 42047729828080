import React, { useEffect, useState } from "react"
import { fetchPageQuery } from "../../utils/apiCalls"
import styles from "./Page.module.css"
import "./wpstyles.css"
import { ReactComponent as Wave } from "./../../assets/wave.svg"
import { CoinItem } from "../../utils/types"
import { useLocation } from "react-router"
import BlogPostCarousell from "../../components/BlogPostCarousell"
import {pageByUri} from "../../hardResponses/pageBy";
import {globalVars} from "../../globalVars";
let classNames = require("classnames")

function Page(): JSX.Element {
  const [isLoading, setLoading] = useState<boolean | string>()
  const [wpPage, setWPPage] = useState<CoinItem>()
  const { pathname } = useLocation()

  useEffect(() => {
    // const ac = new AbortController()
    try {
      if(globalVars.useProxyApiCalls){
        console.log(pathname);
        const res = pageByUri(pathname)
        setWPPage(res?.data.pageBy);
      }else{
      setLoading(true)
      // setShowContent(false)
      fetchPageQuery(pathname).then((res) => {
        if (res) {
          setWPPage(res)
          setLoading("DONE")
          // setShowContent(true)
        } else {
          setLoading("error")
        }
      })}
    } catch (e) {
      // setLoading('error')
    }
    // return () => ac.abort()
  }, [pathname])

  return isLoading === "error" ? (
    <></>
  ) : (
    <ContentSection key={pathname} pathname={pathname} wpPage={wpPage} />
  )
}

export default Page

export const ContentSection = ({
  pathname,
  wpPage,
}: {
  pathname: string
  wpPage: CoinItem | undefined
}) => {
  return (
    <div>
      <ContentFader pathname={pathname} wpPage={wpPage?.title}>
        <div className={styles["hero-image-container"]}>
          <div
            className={styles.heroImage}
            style={
              wpPage?.featuredImage?.node?.sourceUrl
                ? {
                    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 1) 100%),url("${wpPage?.featuredImage?.node?.sourceUrl}")`,
                  }
                : {}
            }
          >
            <img className={styles.heroImgImg} src={wpPage?.featuredImage?.node?.sourceUrl} />
          </div>
          <div className={styles.waveContainer}>
            <Wave />
          </div>
        </div>
        <div className={styles.titleWrapper}>{wpPage && <h1>{wpPage?.title}</h1>}</div>
        <div>{wpPage && <div dangerouslySetInnerHTML={{ __html: wpPage?.content }} />}</div>
        {wpPage?.postCarousellCategory?.postCarousellCategory && (
          <>
            <div className={styles.divider} />
            <BlogPostCarousell category={wpPage?.postCarousellCategory?.postCarousellCategory} />
          </>
        )}
      </ContentFader>
    </div>
  )
}

const ContentFader = ({
  children,
  pathname,
  wpPage,
}: {
  children: any
  pathname: string
  wpPage: string | undefined
}) => {
  const [showContent, setShowContent] = useState<boolean>()

  useEffect(() => {
    setShowContent(true)
  }, [wpPage])
  // useEffect(() => {
  //   setShowContent(false)
  // }, [pathname])

  return <div className={classNames("contentFader", showContent ? "show" : "hide")}>{children}</div>
}
