export function pages(lang: "DE" | "EN") {

    if (lang === "DE")
        return {
            "data": {
                "pages": {
                    "nodes": [{
                        "title": "Kontakt & Impressum",
                        "uri": "\/de\/kontakt-impressum\/",
                        "showPageWhere": {"showpagewhere": ["showInFooter", "showInHeaderTop"], "lefttorightorder": 100}
                    }, {
                        "title": "Der Erfahrungsraum",
                        "uri": "\/de\/der-erfahrungsraum\/",
                        "showPageWhere": {"showpagewhere": ["showInPagesMenu"], "lefttorightorder": 100}
                    }, {
                        "title": "Die Handlungen",
                        "uri": "\/de\/die-handlungen\/",
                        "showPageWhere": {"showpagewhere": ["showInPagesMenu"], "lefttorightorder": 3}
                    }, {
                        "title": "Der Zugang",
                        "uri": "\/de\/der-zugang\/",
                        "showPageWhere": {"showpagewhere": ["showInPagesMenu"], "lefttorightorder": 2}
                    }, {
                        "title": "Der Raum",
                        "uri": "\/",
                        "showPageWhere": {"showpagewhere": ["showInPagesMenu"], "lefttorightorder": 1}
                    }]
                }
            },
            "extensions": {
                "debug": [{
                    "type": "DEBUG_LOGS_INACTIVE",
                    "message": "GraphQL Debug logging is not active. To see debug logs, GRAPHQL_DEBUG must be enabled."
                }]
            }
        };
}