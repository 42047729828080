import bgLogin from "../assets/images/bg_login_b-1.jpg";
import bg_sparring_woph from "../assets/images/bg_sparring_woph-b.jpg";
import bg_coaching_b from "../assets/images/bg_coaching_b.jpg";
import carousel_cgc_website_01 from "../assets/images/carousel-cgc-website-09_b-1.png";
import carousel_cgc_website_02 from "../assets/images/carousel-cgc-website-02.png";
import carousel_cgc_website_03 from "../assets/images/carousel-cgc-website-03.png";
import carousel_cgc_website_04 from "../assets/images/carousel-cgc-website-04.png";
import carousel_cgc_website_05 from "../assets/images/carousel-cgc-website-05.png";
import carousel_cgc_website_06 from "../assets/images/carousel-cgc-website-06.png";
import carousel_cgc_website_07 from "../assets/images/carousel-cgc-website-07.png";

export function posts(categoryName : string ) {

    if (categoryName === "jobhistory")
        return {
            "data": {
                "posts": {
                    "nodes": [{
                        "title": "Informationstechnologie",
                        "history": {
                            "description": "- IT Strategie und Umsetzungsbegleitung <\/br> - Projektmanagement <\/br> - Software Entwicklung Oracle, Business Intelligence",
                            "fieldGroupName": "history",
                            "from": "IT Strategie - Umsetzung",
                            "to": "(Softwareentwicklung)",
                            "lefttorightorder": 4
                        },
                        "featuredImage": {"node": {"sourceUrl": bgLogin}}
                    }, {
                        "title": "Projekt- und Prozessmanagement",
                        "history": {
                            "description": "- Analyse und Konzeption von komplexen Gesch\u00e4ftsprozessen <\/br>  - Organisations\u00fcber- greifendes Projektmanagement ",
                            "fieldGroupName": "history",
                            "from": "Analyse - Konzeption ",
                            "to": "Einf\u00fchrung - Optimierung",
                            "lefttorightorder": 3
                        },
                        "featuredImage": {"node": {"sourceUrl": bg_sparring_woph}}
                    }, {
                        "title": "Organisationsentwicklung",
                        "history": {
                            "description": "- Einf\u00fchrung von operativen Werkzeugen f\u00fcr die Organisationsentwicklung <\/br> - Unterst\u00fctzung beim Aufbau von komplexen Organisationsstrukturen",
                            "fieldGroupName": "history",
                            "from": "F\u00fchrung - Teams",
                            "to": "Organisationsaufbau",
                            "lefttorightorder": 2
                        },
                        "featuredImage": {"node": {"sourceUrl": bg_coaching_b}}
                    }, {
                        "title": "Strategieumsetzung",
                        "history": {
                            "description": "- Operative Umsetzung von Vision und Strategie mithilfe von KPI's und Business Intellegence <\/br> - Vortrags- und Trainingsaktivit\u00e4ten f\u00fcr Strategieumsetzung ",
                            "fieldGroupName": "history",
                            "from": "Vision - Strategie ",
                            "to": "KPI",
                            "lefttorightorder": 1
                        },
                        "featuredImage": {"node": {"sourceUrl": carousel_cgc_website_01}}
                    }]
                }
            }
        };
    if (categoryName === "der-raum")
        return {
            "data": {
                "posts": {
                    "nodes": [{
                        "title": "Graz\/Wien\/Nepal",
                        "history": {
                            "description": "NGO zur St\u00e4rkung von Frauen und M\u00e4dchen in Nepal:  <a href=http:\/\/www.rootsforlife.info\/>Roots for Life<\/a>",
                            "fieldGroupName": "history",
                            "from": "2013",
                            "to": "NOW",
                            "lefttorightorder": 2
                        },
                        "featuredImage": {"node": {"sourceUrl": carousel_cgc_website_02}}
                    }, {
                        "title": "Wien\/Graz\/Alpbach\/Nepal",
                        "history": {
                            "description": "- Management Coaching, Unternehmensberatung, <\/br> - Prozess- und Projektmanagement, <\/br> - Permakultur, NGO Beratung",
                            "fieldGroupName": "history",
                            "from": "2020",
                            "to": "NOW",
                            "lefttorightorder": null
                        },
                        "featuredImage": {"node": {"sourceUrl": carousel_cgc_website_03}}
                    }, {
                        "title": "Graz\/Wien\/Salzburg\/Alpbach\/Nepal",
                        "history": {
                            "description": "- Management Coaching, Unternehmensberatung, <\/br> - Prozess- und Projektmanagement",
                            "fieldGroupName": "history",
                            "from": "2005",
                            "to": "2020",
                            "lefttorightorder": 3
                        },
                        "featuredImage": {"node": {"sourceUrl": carousel_cgc_website_01}}
                    }, {
                        "title": "Graz\/Wien\/Paris\/M\u00fcnchen",
                        "history": {
                            "description": "- Integration in den Altran Konzern <\/br> - Training und Coaching ",
                            "fieldGroupName": "history",
                            "from": "2001",
                            "to": "2005",
                            "lefttorightorder": 4
                        },
                        "featuredImage": {"node": {"sourceUrl": carousel_cgc_website_05}}
                    }, {
                        "title": "Graz\/Wien",
                        "history": {
                            "description": "- Softwareentwicklung <\/br>- Engineering <\/br> - Unternehmensberatung",
                            "fieldGroupName": "history",
                            "from": "1994",
                            "to": "2001",
                            "lefttorightorder": 5
                        },
                        "featuredImage": {"node": {"sourceUrl": carousel_cgc_website_06}}
                    }, {
                        "title": "Ausbildung",
                        "history": {
                            "description": "<b>DI techn. Mathematik<\/b>, TU Graz <\/br>  <b> Umwelttechnik<\/b>, TU Graz <\/br> <b>MBE<\/b>, St. Gallen\/Berkeley <\/br> <b>Aufsichtsr\u00e4tin<\/b>, WU Wien <\/br> <b>ger. beeid. Sachverst\u00e4ndige<\/b>, Graz <\/br> <b>Permakultur-Design<\/b> (laufend), Wien",
                            "fieldGroupName": "history",
                            "from": "1979",
                            "to": "NOW",
                            "lefttorightorder": 6
                        },
                        "featuredImage": {"node": {"sourceUrl": carousel_cgc_website_07}}
                    }]
                }
            }
        }
    return "";
}