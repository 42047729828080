import React, { RefObject, useState } from "react"
import { FooterLinksType, HeaderQueryRes } from "../utils/types"
import styles from "./BaseLayout.module.css"
import MediaQuery from "react-responsive"
import { Link, NavLink } from "react-router-dom"
import image from "../assets/Gosch_Logo_RGB-1980x426.jpg"
import classNames from "classnames"
import { Button, Menu, Portal, Segment, Sidebar } from "semantic-ui-react"
import { sortByValueFunction } from "../utils/helperFunctions"

const linkActiveStyle = {
  textShadow: "0.5px 0px black",
  transition: "text-shadow 0.5s",
}




export const BaseLayoutHeader = ({
  mainContainerRef,
  footerLinksObject,
  headerPages,
}: {
  mainContainerRef: RefObject<any>
  footerLinksObject: FooterLinksType[]
  headerPages?: HeaderQueryRes[]
}) => {
  const sortedHeader = headerPages?.sort((el1, el2) =>
    sortByValueFunction(el1?.showPageWhere?.lefttorightorder, el2?.showPageWhere?.lefttorightorder),
  )

  return (
    <div className={styles["base-header"]}>
      <MediaQuery minWidth={1024}>
        <div className={styles["base-inner-header"]}>
          <div className={styles["link-container"]}>
            {footerLinksObject.map((linkObj, index) => (
              <Link key={index} className={styles.baseLayoutLinks} to={linkObj.to}>
                {linkObj.text}
              </Link>
            ))}
          </div>
        </div>
      </MediaQuery>
      <div className={styles.lowerHeader}>
        <div className={styles.logoContainer}>
          <Link to={"/"}>
            <img className={styles.logo} src={image} alt={""} />
          </Link>
        </div>
        <MediaQuery minWidth={800}>
          <div className={styles["lower-link-container"]}>
            {sortedHeader?.map(
              (page, index) =>
                page.showPageWhere?.showpagewhere[0] === "showInPagesMenu" && (
                  <NavLink
                    end
                    key={index}
                    className={classNames(styles.baseLayoutLinks, styles.fadeInnLinks)}
                    activeStyle={linkActiveStyle}
                    to={{ pathname: page.uri }}
                  >
                    <h3>{page.title}</h3>
                  </NavLink>
                ),
            )}
          </div>
        </MediaQuery>

        <MediaQuery maxWidth={800}>
          <SideBarPortal mainContainerRef={mainContainerRef} headerPages={headerPages} />
        </MediaQuery>
      </div>
    </div>
  )
}

const SideBarPortal = ({
  mainContainerRef,
  headerPages,
}: {
  mainContainerRef: RefObject<any>
  headerPages?: HeaderQueryRes[]
}) => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false)

  return (
    <div className={styles["lower-link-container-mobile"]}>
      <Portal
        open={mainContainerRef.current && true}
        mountNode={mainContainerRef.current}
        closeOnTriggerClick
        openOnTriggerClick
        trigger={
          <Button
            icon={"bars"}
            style={{ float: "right", backgroundColor: showSidebar && "#f795a7" }}
            onClick={() => setShowSidebar(!showSidebar)}
          />
        }
      >
        <Sidebar.Pushable
          as={Segment}
          style={{
            position: "relative",
            zIndex: 10,
            overflowY: "hidden",
            overflowX: "visible",
            border: "none",
            maxWidth: "89px",
            minWidth: "150px",
            backgroundColor: "transparent",
            boxShadow: !showSidebar && "none",
          }}
        >
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            inverted
            onHide={() => setShowSidebar(false)}
            vertical
            visible={showSidebar}
            width="thin"
          >
            {headerPages?.map((page, index) => (
              <Menu.Item key={index}>
                <NavLink
                  end
                  key={page.uri}
                  activeStyle={linkActiveStyle}
                  to={{ pathname: page.uri }}
                >
                  <h3>{page.title}</h3>
                </NavLink>
              </Menu.Item>
            ))}
          </Sidebar>

          <Sidebar.Pusher
            style={{
              position: "relative",
              zIndex: 10,
              maxWidth: "10vw",
            }}
          >
            <Segment basic />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Portal>
    </div>
  )
}
